import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "./i18n";
import defaultImage from "../public/img/icon_x128.png";
import VueCookies from "vue-cookies";
import VueCookieComply from "vue-cookie-comply";
import "vue-cookie-comply/dist/style.css";
import Vue3Progress from "vue3-progress";

let app = createApp(App);

const options = {
  position: "fixed",
  height: "3px",
  color: "#818181",
};

// global default image
app.config.globalProperties.defaultImageApp = defaultImage;

app
  .use(VueCookies)
  .use(VueCookieComply)
  .use(i18n)
  .use(store)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(Vue3Progress, options)
  .mount("#app");

app.$cookies.config("7d");



