import { createStore } from "vuex";
import Settings from "./settings";
import Products from "./products";
import Auth from "../modules/module-1-auth/store";
import Orders from "./orders";
import Shops from "./shops";
import Points from "./points";
import Transactions from "./transactions";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  modules: {
    settings: Settings,
    products: Products,
    auth: Auth,
    orders: Orders,
    shops: Shops,
    points: Points,
    transactions: Transactions,
  },
});
